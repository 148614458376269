import {
    BusinessRulesApproval,
    ConfirmationDialog,
    ProgressDialog,
    ViewBusinessRuleDetails,
    syncTranslations
} from '@driscollsinc/ggs-business-rules-module-ui';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import { ROUTES, getRoute } from 'constants/routes';
import useBusinessRules from 'hooks/v2/useBusinessRules';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { urls } from 'constants/urls';
import { setPageTitleAction, showToast } from '../../../actions/actions';
import { setSettlementWorkbenchData } from '../../../actions/settlementWorkbenchAction';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'routes/withRouter';
import { DriscollsButton } from '@driscollsinc/style-guide-web2.0';
import { ButtonSize } from '@driscollsinc/style-guide-web2.0';
import { ButtonVariant } from '@driscollsinc/style-guide-web2.0';
import { BUSINESSRULE_CARD_FIELDS } from 'data/mock/BusinessRules';
import useGetFilterData from 'hooks/v2/useGetFilterData';
import { DriscollsLoader } from '@driscollsinc/style-guide-web2.0';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { getLanguage, getNameFromEmail, userBusinessUnit } from 'utils/helper';
import { DrcTranslate } from 'drc/driscolls-react-components';
import useGAAnalytics from 'hooks/v2/useGAAnalytics';
import { EventConstants } from 'data/EventConstants';
import useLang from 'hooks/v2/useLang';
import { NAMESPACE } from 'i18n';

export interface ISelectedItems {
    producingArea: ISelectItem[];
    grower: ISelectItem[];
    ranch: ISelectItem[];
    berryType: ISelectItem[];
    fieldType: ISelectItem[];
    brand: ISelectItem[];
    item: ISelectItem[];
    updatedField?: string;
}

export interface ISelectItem {
    value: string;
    label: string;
}

const BusinessRulesApprovalView = (props) => {
    const {
        businessRuleTypes,
        getBusinessRuleTypes,
        rulesForApproverLoading,
        getBusinessRulesListForApprovers,
        businessRulesListForApprover,
        businessRulesTotalCount,
        ruleDetails,
        getRuleDetails,
        approveOrRejectBusinessRule,
        getApprovers,
        responseTime,
        typesLoading: tabsLoading
    } = useBusinessRules(props.oktaAuth);
    const navigate = useNavigate();
    const { logEvent, logError } = useGAAnalytics();
    const language = localStorage.getItem('GGS_UserLang');
    const itemsPerPage = 10;
    const [searchParams] = useSearchParams();
    const selectedTabURI = searchParams.get(urls.SELECTED_TAB);
    const [tabs, setTabs] = useState([]);
    const [businessRulesList, setBusinessRulesList] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [selectedRuleId, setSelectedRuleId] = useState(selectedTabURI || '1');
    const [selectedRuleIdForRowExpand, setSelectedRuleIdForRowExpand] = useState<string>();
    const [businessRuleCardFields, setBusinessRuleCardFields] = useState(BUSINESSRULE_CARD_FIELDS['default']);
    const { getFormatedBusinessRuleCardFields } = useGetFilterData();
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [multiExpandedContent, setMultiExpandedContent] = useState<any>();
    const [selectedRowIds, setSelectedRowIds] = useState<
        { ruleId: string; createdBy: string; ruleName: string; startDate: string; modifiedBy: string }[]
    >([]);
    const [successCount, setSuccessCount] = useState<number>(0);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
    const [confirmationMessage, setConfirmationMessage] = useState<string>('');
    const [isApproval, setIsApproval] = useState<boolean | undefined>();
    const [openProgressBar, setOpenProgressBar] = useState<boolean>(false);
    const [selectedRowItem, setSelectedRowItem] = useState({
        producingArea: [],
        grower: [],
        ranch: [],
        berryType: [],
        fieldType: [],
        brand: [],
        item: []
    });
    const { getTextTranslated } = useLang();
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;

    useEffect(() => {
        getApprovers();
    }, []);

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    useEffect(() => {
        setTotalItemsCount(businessRulesTotalCount);
    }, [businessRulesTotalCount]);

    const onPageChange = (pageNo: number) => {
        if (pageNo) {
            setPageNo(pageNo);
        }
    };

    const goBack = () => {
        navigate(getRoute(ROUTES.BUSINESS_RULES_LIST, props.ruleType));
    };

    const onTabChange = (index: number, value?: string) => {
        if (value) {
            setSelectedRuleId(value);
            setSelectedTab(index);
            getBusinessRulesListForApprovers(value, itemsPerPage, pageNo);
            setSelectedRowIds([]);
        }
    };

    const formatRuleDetails = (details) => {
        let berryType;
        let fieldType;
        let brand;
        let item;
        if (details && details.RanchAssignment && details.RanchAssignment.length > 0) {
            let producingArea = details.RanchAssignment.map((item) => {
                return { value: item.ProducingAreaCode, label: `${item.ProducingAreaCode} - ${item.ProducingAreaName}` };
            });
            producingArea = [...new Map(producingArea.map((item) => [item['value'], item])).values()];
            let grower = details.RanchAssignment.map((item) => {
                return { value: item.GrowerNbr, label: `${item.GrowerNbr} - ${item.GrowerName}` };
            });
            grower = [...new Map(grower.map((item) => [item['value'], item])).values()];
            let ranch = details.RanchAssignment.map((item) => {
                return { value: item.RanchNbr, label: `${item.RanchNbr} - ${item.RanchName}` };
            });
            ranch = [...new Map(ranch.map((item) => [item['value'], item])).values()];

            if (details.ItemAssignment && details.ItemAssignment.length > 0) {
                berryType = details.ItemAssignment.map((item) => {
                    return { value: item.BerryType, label: item.BerryType };
                });
                berryType = [...new Map(berryType.map((item) => [item['value'], item])).values()];
                fieldType = details.ItemAssignment.map((item) => {
                    return { value: item.FieldType, label: item.FieldType };
                });
                fieldType = [...new Map(fieldType.map((item) => [item['value'], item])).values()];
                brand = details.ItemAssignment.map((item) => {
                    return { value: item.Brand, label: item.Brand };
                });
                brand = [...new Map(brand.map((item) => [item['value'], item])).values()];
                item = details.ItemAssignment.map((item) => {
                    return { value: item.ItemNbr, label: `${item.ItemNbr} - ${item.ItemName}` };
                });
                item = [...new Map(item.map((item) => [item['value'], item])).values()];
            }
            setSelectedRowItem({
                producingArea,
                grower,
                ranch,
                berryType,
                fieldType,
                brand,
                item
            });
        } else {
            setSelectedRowItem({
                producingArea: [],
                grower: [],
                ranch: [],
                berryType: [],
                fieldType: [],
                brand: [],
                item: []
            });
        }
    };

    const updateBusinessRuleCardFields = (details) => {
        if (details && details.RuleTypeId) {
            let cardFields = getFormatedBusinessRuleCardFields(
                details,
                false,
                BUSINESSRULE_CARD_FIELDS[details.RuleTypeId] || BUSINESSRULE_CARD_FIELDS['default']
            );
            setBusinessRuleCardFields({
                ...cardFields,
                startDate: { ...cardFields.startDate, value: DuDateUtilities.FormatDateFromIso(cardFields.startDate.value, '') },
                endDate: { ...cardFields.endDate, value: DuDateUtilities.FormatDateFromIso(cardFields.endDate.value, '') }
            });
        }
    };

    useEffect(() => {
        if (ruleDetails && ruleDetails.length > 0) {
            updateBusinessRuleCardFields(ruleDetails[0]);
            formatRuleDetails(ruleDetails[0]);
        }
    }, [ruleDetails]);

    useEffect(() => {
        getBusinessRuleTypes();
    }, []);

    useEffect(() => {
        if (!tabsLoading && selectedRuleId) {
            getBusinessRulesListForApprovers(selectedRuleId, itemsPerPage, pageNo);
        }
    }, [pageNo, searchParams]);

    useEffect(() => {
        if (businessRulesListForApprover && businessRulesListForApprover.length > 0) {
            const formatedBusinessRuleList = businessRulesListForApprover.map((item) => {
                return {
                    id: item.RuleId,
                    description: `${item.RuleId} - ${item.RuleName}`,
                    start_date: item.StartDate ? DuDateUtilities.FormatDateFromIso(item.StartDate) : '',
                    end_date: item.EndDate ? DuDateUtilities.FormatDateFromIso(item.EndDate) : '',
                    status: item.Status,
                    createdBy: item.CreatedBy,
                    createdDateTime: item.CreatedDateTime ? DuDateUtilities.FormatDateFromIso(item.CreatedDateTime) : null,
                    modifiedBy: item.ModifiedBy,
                    modifiedDateTime: item.ModifiedDateTime ? DuDateUtilities.FormatDateFromIso(item.ModifiedDateTime) : null,
                    approver: item.Approver,
                    approvedDateTime: item.ApprovedDateTime ? DuDateUtilities.FormatDateFromIso(item.ApprovedDateTime) : null
                };
            });
            setBusinessRulesList(formatedBusinessRuleList);
        } else {
            setBusinessRulesList([]);
        }
    }, [businessRulesListForApprover]);

    useEffect(() => {
        const businessRuleTypeIdsToRemove = [12, 13, 14];
        if (businessRuleTypes && businessRuleTypes.length > 0) {
            const tabList = (businessRuleTypes as any[])
                .filter((item) => !businessRuleTypeIdsToRemove.includes(item.RuleTypeId))
                .map((item) => {
                    return {
                        value: item.RuleTypeId,
                        label: item.RuleType,
                        count: item.RuleCount
                    };
                });
            setTabs(tabList);
            if (tabList.length > 0) {
                setSelectedRuleId(selectedRuleId || tabList[0].value);
                const index: number = tabList.findIndex((item) => {
                    return item.value === parseInt(selectedRuleId);
                });
                setSelectedTab(index || 0);
            }
        }
    }, [businessRuleTypes]);

    useEffect(() => {
        /**@ts-ignore */
        const isFetched = ruleDetails && selectedRuleIdForRowExpand === ruleDetails[0].RuleId;
        if (businessRuleCardFields && selectedRowItem && selectedRuleIdForRowExpand && isFetched) {
            setMultiExpandedContent((prev) => ({
                ...prev,
                [selectedRuleIdForRowExpand]: {
                    businessRuleCardFields: businessRuleCardFields,
                    summaryData: selectedRowItem
                }
            }));
        }
    }, [selectedRuleIdForRowExpand, ruleDetails, businessRuleCardFields, selectedRowItem]);

    // Checks if the businessRuleCardFields and Summary data have data in their objects.
    const isObjectIsEmpty = (businessRuleObject: boolean, obj) => {
        if (businessRuleObject) {
            return obj.description.value ? false : true;
        } else {
            obj.producingArea.length > 0 ? false : true;
        }
    };

    /** This function is invoked from Driscolls Data table to render the expanded content.
     *  Here the function handles the API call (ruleDetail API) when the row is expanded. */
    const renderRuleDetailView = (id: string, row: any) => {
        const ruleId = row?.description.split(' ')[0];
        setSelectedRuleIdForRowExpand(ruleId);

        if (!multiExpandedContent) {
            // Checks whether the expandedContent state has data.
            getRuleDetails(ruleId);
        } else if (!multiExpandedContent[ruleId]) {
            // Checks whether the expandedContent state for a particular ruleId has data.
            getRuleDetails(ruleId);
        } else if (
            multiExpandedContent &&
            multiExpandedContent[ruleId] &&
            isObjectIsEmpty(true, multiExpandedContent[ruleId]?.businessRuleCardFields) // Checks if businessRuleCardFields have data after API call.
        ) {
            getRuleDetails(ruleId);
        }

        if (!multiExpandedContent || !multiExpandedContent[ruleId]) {
            // Shows loading when the expanded content state data is empty
            return (
                <div style={{ display: 'flex', justifyContent: 'center', transform: 'scale(0.5)' }}>
                    <DriscollsLoader />
                </div>
            );
        }
        // After the API call is successful and the data has been set in the respective states, the View Business Rule is displayed.
        if (multiExpandedContent && multiExpandedContent[ruleId] && !isObjectIsEmpty(true, multiExpandedContent[ruleId].businessRuleCardFields)) {
            return (
                <ViewBusinessRuleDetails
                    businessRuleCardFields={multiExpandedContent[ruleId]?.businessRuleCardFields}
                    summaryData={multiExpandedContent[ruleId]?.summaryData}
                />
            );
        }
    };

    const onApproveAll = () => {
        setOpenConfirmationDialog(true);
        setConfirmationMessage(`Are you sure you want to approve the selected Business Rules (${selectedRowIds.length}) ?`);
        setIsApproval(true);
    };

    const onRejectAll = () => {
        setOpenConfirmationDialog(true);
        setConfirmationMessage(`Are you sure you want to reject the selected Business Rules (${selectedRowIds.length})`);
        setIsApproval(false);
    };

    useEffect(() => {
        if (successCount > 0 && selectedRowIds.length > 0) {
            if (successCount === selectedRowIds.length) {
                setOpenProgressBar(false);
                props.showToast(
                    getTextTranslated(`The selected Business Rules have been ${isApproval ? 'Approved' : 'Rejected'}`, NAMESPACE.BUSINESSRULES),
                    true
                );
                setSuccessCount(0);
                getBusinessRulesListForApprovers(selectedRuleId, itemsPerPage, pageNo);
            }
        }
    }, [successCount]);

    /**
     * The function `handleApproveAll` asynchronously approves multiple business rules with the
     * provided comments and updates the success count accordingly.
     * @param {string | undefined} comments - The `handleApproveAll` function is
     * responsible for handling the approval of multiple business rules in a list.
     */
    const handleApproveAll = async (comments: string | undefined) => {
        logEvent(EventConstants.BUSINESS_RULE_BULK_APPROVE, { selectedRowIds });
        setOpenConfirmationDialog(false);
        setOpenProgressBar(true);
        let token = await props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        let loggedInUserName = DuAuthenticationUtilities.GetUserId(token);
        selectedRowIds.map(async (item) => {
            const approveRequester = getNameFromEmail(item?.modifiedBy);
            const data = {
                Action: 'APPROVE',
                BusinessRule: {
                    RuleId: item.ruleId,
                    BusinessUnit: userBusinessUnit(),
                    RuleName: item.ruleName,
                    StartDate: item.startDate,
                    CreatedBy: item.createdBy,
                    RuleApprovedBy: loggedInUserName,
                    Reason: comments ?? ''
                },
                UserName: getNameFromEmail(approveRequester),
                UserEmail: approveRequester,
                RuleApprovedOn: formattedDate,
                Comments: comments ?? '',
                WebURL: ROUTES.BUSINESS_RULES
            };
            try {
                const success = await approveOrRejectBusinessRule(data);
                if (success) {
                    setSuccessCount((prev) => prev + 1);
                }
                return success;
            } catch (err) {
                setSuccessCount((prev) => prev + 1);
                return `Error: ${err}`;
            }
        });
    };

    /**
     * The function `handleRejectAll` asynchronously processes a list of selected items by sending
     * rejection requests with specified data and handling success or error responses accordingly.
     * @param {string} comments - The `comments` parameter in the `handleRejectAll` function is a string
     * that represents the rejection reason. This function is responsible
     * for handling the rejection of multiple business rules by sending a rejection request.
     */
    const handleRejectAll = async (comments: string) => {
        logEvent(EventConstants.BUSINESS_RULE_BULK_REJECT, { selectedRowIds });
        setOpenConfirmationDialog(false);
        setOpenProgressBar(true);
        let token = await props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        let loggedInUserName = DuAuthenticationUtilities.GetUserId(token);
        selectedRowIds.map(async (item) => {
            const approveRequester = getNameFromEmail(item?.modifiedBy);
            const data = {
                Action: 'REJECT',
                BusinessRule: {
                    RuleId: item.ruleId,
                    BusinessUnit: userBusinessUnit(),
                    RuleName: item.ruleName,
                    StartDate: item.startDate,
                    CreatedBy: item.createdBy,
                    RuleRejectedBy: loggedInUserName,
                    Reason: comments ?? ''
                },
                UserName: getNameFromEmail(approveRequester),
                UserEmail: approveRequester,
                RuleRejectedOn: formattedDate,
                RejectionReason: comments,
                WebURL: ROUTES.BUSINESS_RULES
            };
            try {
                const success = await approveOrRejectBusinessRule(data);
                if (success) {
                    setSuccessCount((prevCount) => prevCount + 1);
                }
                return success;
            } catch (err) {
                setSuccessCount((prev) => prev + 1);
                return `Error: ${err}`;
            }
        });
    };

    /**
     * The function `onRowApprove` handles the approval of a business rule with error handling and
     * displaying a success or error message.
     * @param approveData - The `approveData` parameter contain information related to a
     * business rule approval process. It includes properties such as `createdBy`, `ruleId`, and possibly
     * other details specific to the business rule being approved.
     * @param comments - The `comments` parameter in the `onRowApprove` function is used to provide any
     * additional comments or notes related to the approval of a business rule.
     */
    const onRowApprove = async (approveData, comments) => {
        logEvent(EventConstants.BUSINESS_RULE_APPROVE, { approveRuleId: approveData.ruleId });
        const approveRequester = getNameFromEmail(approveData?.modifiedBy);
        let token = await props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        let loggedInUserName = DuAuthenticationUtilities.GetUserId(token);

        const data = {
            Action: 'APPROVE',
            BusinessRule: {
                RuleId: approveData.ruleId,
                BusinessUnit: userBusinessUnit(),
                RuleName: approveData.ruleName,
                StartDate: approveData.startDate,
                CreatedBy: approveData.createdBy,
                RuleApprovedBy: loggedInUserName,
                Reason: comments ?? ''
            },
            UserName: getNameFromEmail(approveData?.modifiedBy),
            UserEmail: approveData?.modifiedBy,
            RuleApprovedOn: formattedDate,
            Comments: comments ?? '',
            WebURL: ROUTES.BUSINESS_RULES
        };
        try {
            await approveOrRejectBusinessRule(data);
            props.showToast(getTextTranslated('The Business Rule Is Approved', NAMESPACE.BUSINESSRULES), true);
            getBusinessRulesListForApprovers(selectedRuleId, itemsPerPage, pageNo);
        } catch (err) {
            props.showToast(`Error: ${err}`);
        }
    };

    /**
     * The function `onRowReject` handles the rejection of a business rule with error handling and
     * updating the UI accordingly.
     * @param rejectData - rejectData is an object containing information about the rule being
     * rejected, such as ruleId, createdBy, etc. It is used to construct the data object that will be
     * sent to the backend to reject the business rule.
     * @param comments - The `comments` parameter in the `onRowReject` function represents the reason
     * for rejecting a business rule. This could be a string containing any additional information or
     * explanation for why the rule is being rejected.
     */
    const onRowReject = async (rejectData, comments) => {
        logEvent(EventConstants.BUSINESS_RULE_REJECT, { rejectedRuleId: rejectData.ruleId });
        let token = await props.oktaAuth.getAccessToken();
        let loggedInUserName = DuAuthenticationUtilities.GetUserId(token);
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        const data = {
            Action: 'REJECT',
            BusinessRule: {
                RuleId: rejectData.ruleId,
                BusinessUnit: userBusinessUnit(),
                RuleName: rejectData.ruleName,
                StartDate: rejectData.startDate,
                CreatedBy: rejectData.createdBy,
                RuleRejectedBy: loggedInUserName,
                Reason: comments ?? ''
            },
            UserName: getNameFromEmail(rejectData?.modifiedBy),
            UserEmail: rejectData?.modifiedBy,
            RuleRejectedOn: formattedDate,
            RejectionReason: comments,
            WebURL: ROUTES.BUSINESS_RULES
        };
        try {
            await approveOrRejectBusinessRule(data);
            props.showToast(getTextTranslated('The Business Rule Is Rejected', NAMESPACE.BUSINESSRULES), true);
            getBusinessRulesListForApprovers(selectedRuleId, itemsPerPage, pageNo);
        } catch (err) {
            props.showToast(`Error: ${err}`);
        }
    };

    return (
        <DrcWrapper>
            <Stepper page={'Business Rules Approval'} showBackButton={true} showPageTitle={false} goBack={goBack} />
            <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <DriscollsButton
                    onClick={onRejectAll}
                    disabled={selectedRowIds.length <= 0}
                    buttonSize={ButtonSize.SMALL}
                    variant={ButtonVariant.SECONDARY}
                >
                    Reject
                </DriscollsButton>
                <DriscollsButton
                    onClick={onApproveAll}
                    disabled={selectedRowIds.length <= 0}
                    buttonSize={ButtonSize.SMALL}
                    variant={ButtonVariant.PRIMARY}
                >
                    Approve
                </DriscollsButton>
            </div>

            <BusinessRulesApproval
                tabs={tabs}
                isLoading={tabsLoading}
                currentPage={pageNo}
                isTableLoading={rulesForApproverLoading}
                data={businessRulesList}
                selectedTab={selectedTab}
                totalItems={isNaN(totalItemsCount) || totalItemsCount <= 0 ? 1 : totalItemsCount}
                itemsPerPage={10}
                onTabChange={onTabChange}
                onPageChange={onPageChange}
                displayTabsCount={5}
                noRowMessage={'No data found'}
                renderExpandedContent={renderRuleDetailView}
                setSelectedRowIds={setSelectedRowIds}
                selectedRowIds={selectedRowIds}
                onApprove={onRowApprove}
                onReject={onRowReject}
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: '16px' }}>
                <DrcTranslate>{'Results loaded in'}</DrcTranslate> {`${responseTime.toFixed(1)} s`}
            </div>
            <ConfirmationDialog
                isOpen={openConfirmationDialog}
                onClose={() => setOpenConfirmationDialog(false)}
                onCancel={() => setOpenConfirmationDialog(false)}
                showTextField={true}
                isReasonRequired={isApproval ? false : true}
                message={confirmationMessage}
                onConfirm={(comments: string) => (isApproval ? handleApproveAll(comments) : handleRejectAll(comments))}
            />
            <ProgressDialog
                totalSteps={selectedRowIds.length}
                completedSteps={successCount}
                progressValue={successCount}
                title={'Business rule Approval Progress'}
                isOpen={openProgressBar}
                onClose={() => setOpenProgressBar(false)}
            />
        </DrcWrapper>
    );
};

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(BusinessRulesApprovalView)));
