import { BusinessRulesEntryPoint, syncTranslations } from '@driscollsinc/ggs-business-rules-module-ui';
import useLang from 'hooks/v2/useLang';
import { withOktaAuth } from '@okta/okta-react';
import { setPageTitleAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import { BusinessRuleActions } from 'constants/BusinessRuleActions';
import { ROUTES, getRoute } from 'constants/routes';
import { urls } from 'constants/urls';
import { EventConstants } from 'data/EventConstants';
import { DrcTranslate } from 'drc/driscolls-react-components';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';
import useBusinessRules from 'hooks/v2/useBusinessRules';
import useGAAnalytics from 'hooks/v2/useGAAnalytics';
import { NAMESPACE } from 'i18n';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { withRouter } from 'routes/withRouter';
import { getLanguage, userBusinessUnit } from 'utils/helper';
import ApplicationContext from 'context/ApplicationContext';

function BusinessRules(props) {
    const { logEvent, logError } = useGAAnalytics();

    const navigate = useNavigate();
    const language = localStorage.getItem('GGS_UserLang');
    const [searchParams] = useSearchParams();
    const itemsPerPage = 25;
    const selectedTabURI = searchParams.get(urls.SELECTED_TAB);
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [businessRulesList, setBusinessRulesList] = useState([]);
    const [selectedRuleId, setSelectedRuleId] = useState(selectedTabURI || '1');
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [serachKeyword, setSerachKeyword] = useState('');
    const [loadingMessage, setLoadingMessage] = useState<string>('Loading');
    const { getTextTranslated } = useLang();

    const {
        submitRule,
        typesError,
        ruleCreateResponse,
        ruleCreateError,
        typesLoading: tabsLoading,
        getBusinessRuleTypes,
        businessRuleTypes,
        getBusinessRules,
        rulesLoading,
        businessRules,
        responseTime,
        businessRulesTotalCount,
        rulesError
    } = useBusinessRules(props.oktaAuth);


    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    useEffect(() => {
        setTotalItemsCount(businessRulesTotalCount);
    }, [businessRulesTotalCount]);

    useEffect(() => {
        if (businessRules && businessRules.length > 0) {
            const formatedBusinessRuleList = businessRules.map((item) => {
                return {
                    id: item.RuleId,
                    ruleName: item.RuleName,
                    description: `${item.RuleId} - ${item.RuleName}`,
                    start_date: item.StartDate ? item.StartDate?.split('T')[0] : '',
                    end_date: item.EndDate ? item.EndDate?.split('T')[0] : '',
                    status: item.Status,
                    ruleType: item.RuleTypeId + '',
                    pricingPoolId: item.PricingPoolId,
                    comment:item.Reason,
                    createdBy: item.CreatedBy,
                    createdDateTime: item.CreatedDateTime ? DuDateUtilities.FormatDateFromIso(item.CreatedDateTime) : null,
                    modifiedBy: item.ModifiedBy,
                    modifiedDateTime: item.ModifiedDateTime ? DuDateUtilities.FormatDateFromIso(item.ModifiedDateTime) : null,
                    approver: item.Approver,
                    approvedDateTime: item.ApprovedDateTime ? DuDateUtilities.FormatDateFromIso(item.ApprovedDateTime) : null
                };
            });
            setBusinessRulesList(formatedBusinessRuleList);
        } else {
            setBusinessRulesList([]);
        }
    }, [businessRules]);

    useEffect(() => {
        if (!tabsLoading && selectedRuleId) {
            getBusinessRules(selectedRuleId, itemsPerPage, pageNo, serachKeyword);
        }
    }, [pageNo, serachKeyword, searchParams]);

    useEffect(() => {
        const businessRuleTypeIdsToRemove = [0];
        if (businessRuleTypes && businessRuleTypes.length > 0) {
            const tabList = (businessRuleTypes as any[])
                .filter((item) => !businessRuleTypeIdsToRemove.includes(item.RuleTypeId))
                .map((item) => {
                    return {
                        ...item,
                        value: item.RuleTypeId,
                        label: item.RuleType,
                        count: item.RuleCount,
                        description: item.Description
                    };
                });
            setTabs(tabList);
            if (tabList.length > 0) {
                const selectedHere = selectedTabURI || tabList[0].value;
                setSelectedRuleId(selectedHere);
                const index: number = tabList.findIndex((item) => {
                    return item.value === parseInt(selectedRuleId);
                });
                setSelectedTab(index || 0);
            }
        }
    }, [businessRuleTypes]);

    useEffect(() => {
        getBusinessRuleTypes();
    }, []);

    const onTabChange = (index: number, value?: string, label?: string) => {
        if (value) {
            logEvent(EventConstants.BUSINESS_RULE_TAB_CHANGE, { selectedTab: label, ruleId: value });
            setPageNo(1);
            setSelectedRuleId(value);
            setSelectedTab(index);
            navigate(getRoute(ROUTES.BUSINESS_RULES_LIST, value));
            setLoadingMessage(`Loading ${label}...`);
        }
    };

    const onPageChange = (pageNo: number) => {
        if (pageNo) {
            setPageNo(pageNo);
        }
    };

    const addNewRule = () => {
        logEvent(EventConstants.BUSINESS_RULE_ADD_NEW_RULE, { RuleId: selectedRuleId });
        navigate(getRoute(ROUTES.BUSINESS_RULES_CREATE, selectedRuleId));
    };

    const onEdit = (id) => {
        logEvent(EventConstants.BUSINESS_RULE_EDIT, { RuleId: id });
        navigate(getRoute(ROUTES.BUSINESS_RULES_EDIT, id));
    };

    const onSearch = (keyword) => {
        logEvent(EventConstants.BUSINESS_RULE_SEARCH, { search: keyword });
        setSerachKeyword(keyword);
    };

    const onCopy = (id) => {
        logEvent(EventConstants.BUSINESS_RULE_COPY, { RuleId: id });
        navigate(getRoute(ROUTES.BUSINESS_RULES_COPY, id));
    };

    const onDelete = (id) => {
        logEvent(EventConstants.BUSINESS_RULE_DELETE, { RuleId: id });
        // Delete rule
        let data = {
            Action: BusinessRuleActions.DELETE,
            BusinessRule: {
                BusinessUnit: userBusinessUnit(),
                RuleId: id,
                RuleTypeId: selectedRuleId
            }
        };
        submitRule(data);
        //Refreshing tab count (TODO optimize this without a server call)
        getBusinessRuleTypes();
    };

    useEffect(() => {
        if (ruleCreateResponse) {
            props.showToast(getTextTranslated('Business Rule deleted successfully', NAMESPACE.BUSINESSRULES), true);
        }
    }, [ruleCreateResponse]);

    useEffect(() => {
        if (ruleCreateResponse && selectedRuleId && pageNo) {
            getBusinessRules(selectedRuleId, itemsPerPage, pageNo, serachKeyword);
        }
        if (ruleCreateError) {
            props.showToast(getTextTranslated('Error Occured while deleting rule', NAMESPACE.BUSINESSRULES), false);
        }
    }, [ruleCreateError, ruleCreateResponse]);

    return (
        <DrcWrapper>
            {props.userIsAdmin && <Navigate to={getRoute(ROUTES.BUSINESS_RULES_APPROVE)} />}
            <Stepper page="Business Rules" showPageTitle={false} />
            <div style={{ padding: '0 20px', height: 'calc(100% - 100px)' }}>
                <BusinessRulesEntryPoint
                    errorMessage={typesError || rulesError}
                    currentPage={pageNo}
                    tabs={tabs}
                    selectedTab={selectedTab}
                    displayTabsCount={5}
                    isLoading={tabsLoading}
                    isTableLoading={rulesLoading}
                    data={businessRulesList}
                    totalItems={isNaN(totalItemsCount) || totalItemsCount <= 0 ? 1 : totalItemsCount}
                    itemsPerPage={itemsPerPage}
                    onTabChange={onTabChange}
                    onPageChange={onPageChange}
                    addNewRule={addNewRule}
                    onEdit={onEdit}
                    onCopy={onCopy}
                    onDelete={onDelete}
                    noRowMessage={'No data found'}
                    onSearch={onSearch}
                    loadingText={loadingMessage}
                    loadingNameSpace="common"
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: '16px' }}>
                    <DrcTranslate>{'Results loaded in'}</DrcTranslate> {`${responseTime.toFixed(1)} s`}
                </div>
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    userIsAdmin: rootReducer.userIsAdmin,
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(BusinessRules)));
