import { EventConstants } from 'data/EventConstants';
import { getCall, postCall } from 'services/v2/Services';
import {
    GET_SETTLEMENT_AGGREGATE,
    GET_SETTLEMENT_BREAKDOWN,
    GET_SETTLEMENT_DATA,
    GET_SETTLEMENT_DETAILS_HEADER,
    SEND_SETTLEMENT_MAIL,
    GET_SETTLEMENT_ENGINE_STATUS,
    POST_SETTLEMENT_RECORD,
    GET_SETTLEMENT_APPROVALS,
    GET_SETTLEMENT_REPORT_DETAILS
} from 'services/v2/SettlementAPI';
import useGAAnalytics from './useGAAnalytics';
import useGetApiTokens from './useGetApiTokens';
import { userBusinessUnit } from 'utils/helper';
import { SettlementActions } from 'constants/SettlementActions';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';
import { APPROVER_GROUP_ID } from 'data/constants';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from 'services/api';
import { useState } from 'react';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

const useSettlement = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const { logEvent, logError } = useGAAnalytics();
    const [approverList, setApproverList] = useState([]);

    //GLUE JOBS
    const GLUE_JOB_PROCESS_SETTLEMENT: string = `Job_aurora_ggs_engine_tables_to_transform_${window.config.ENVIRONMENT_NAME}`;
    const GLUE_JOB_DATE_MOVEMENT: string = `job_aurora_ggs_master_and_engine_tables_to_rds_datalake_redshift_${window.config.ENVIRONMENT_NAME}`;
    const GLUE_JOB_PUBLISH_PDF: string = `job_aurora_ggs_pdf_tableau_to_boundary_${window.config.ENVIRONMENT_NAME}`;

    const getGluePayLoad = (glujobName, poolWeek, advancePaymentPoolWeek, userEmail) => {
        return {
            jobname: glujobName,
            arguments: {
                '--region': userBusinessUnit(),
                '--configYaml': 'growersettlement_config.yaml',
                '--datalake-formats': 'iceberg',
                '--env': window.config.ENVIRONMENT_NAME,
                '--execution': 'adhoc',
                '--poolweek': poolWeek,
                '--advance_poolweek': advancePaymentPoolWeek,
                '--tblProps': 'GGS_DOTA_tables_properties.yaml',
                '--user_details': userEmail,
                '--enable-glue-datacatalog': '',
                '--extra-files': `s3://${window.config.ENVIRONMENT_NAME}-driscolls-datalake-config/growersettlement_config.yaml,s3://${window.config.ENVIRONMENT_NAME}-driscolls-datalake-config/GGS_DOTA_tables_properties.yaml`
            }
        };
    };
    type SettlementOps = keyof typeof SettlementActions;
    const getEntries = async (args: {
        PoolWeek?: string;
        ProducingAreaCode?: string;
        GrowerNbr?: string;
        RanchNbr?: string;
        ItemNbr?: string;
        Status?: string;
    }): Promise<{ [key: string]: string }[]> => {
        const { token } = await getTokenAndId(oktaAuth);
        const response = await postCall(GET_SETTLEMENT_DATA(), token, {}, args);
        return response.raw.data.resultSet1;
    };

    const getAggregate = async (args: {
        PoolWeek: string;
        ProducingAreaCode?: string;
        GrowerNbr?: string;
        RanchNbr?: string;
        PricingPool?: string;
        PFQPool?: string;
    }) => {
        const { token } = await getTokenAndId(oktaAuth);
        const response = await postCall(GET_SETTLEMENT_AGGREGATE(), token, {}, args);
        const data = response.raw.data.resultSet1;
        return {
            headers: [
                {
                    label: 'Name',
                    key: 'RuleType'
                },
                {
                    label: 'Amount',
                    key: 'Amount'
                },
                {
                    label: 'Amount per LB(/LB)',
                    key: 'Rate'
                },
                {
                    label: 'Currency',
                    key: 'Currency'
                }
            ],
            rows: data
        };
    };

    const getDetailHeaders = async (poolWeek: string) => {
        const { token } = await getTokenAndId(oktaAuth);
        const response = await getCall(GET_SETTLEMENT_DETAILS_HEADER(poolWeek), token, {});
        return response.raw.data.resultSet1.map((result) => ({ header: result.Label, value: result.DataValue ?? '0.00', data: result }));
    };

    const getReportDetails = async (params: {
        PoolWeek: string;
        ProducingAreaCode?: string;
        GrowerNbr?: string;
        RanchNbr?: string;
        PricingPool?: string;
        SettlementPool?: string;
        RuleType: string;
        Offset: number;
        Limit: number;
    }) => {
        const { token } = await getTokenAndId(oktaAuth);
        const response = await postCall(GET_SETTLEMENT_REPORT_DETAILS(), token, {}, params);
        return response.raw.data;
    };

    const getDetailsBreakdown = async (poolWeek: string, label: string, offset: number) => {
        const { token } = await getTokenAndId(oktaAuth);
        const response = await getCall(
            GET_SETTLEMENT_BREAKDOWN({ poolweek: poolWeek, label: label, datatype: 'PFQ Pool', offset: offset, limit: 100 }),
            token,
            {}
        );
        return { data: response.raw.data.Data ?? [], totalItems: response.raw.data.TotalCount ?? 0 };
    };

    const getSettlementEngineStatus = async (poolWeek: string) => {
        const { token, userEmail } = await getTokenAndId(oktaAuth);
        const response = await getCall(GET_SETTLEMENT_ENGINE_STATUS(poolWeek), token, {});
        const result = response.raw.data.Data?.[0];
        return result;
    };

    const getSettlementApprovals = async () => {
        const { token } = await getTokenAndId(oktaAuth);
        const response = await getCall(GET_SETTLEMENT_APPROVALS(), token, {});
        return { data: response.raw.data.Data ?? [], totalItems: response.raw.data.Data.length ?? 0 };
    };

    const getSettlementEngine = async (poolWeek: string, advancePaymentPoolWeek: string) => {
        logEvent(EventConstants.GGS_ENGINE_RUN, { poolWeek });
        const { token, userEmail } = await getTokenAndId(oktaAuth);
        const response = await postCall(
            POST_SETTLEMENT_RECORD(poolWeek),
            token,
            {},
            {
                SettlementData: {
                    LoginId: userEmail,
                    Action: SettlementActions.PROCESS
                },
                GlueRequest: getGluePayLoad(GLUE_JOB_PROCESS_SETTLEMENT, poolWeek, advancePaymentPoolWeek, userEmail)
            }
        );
        if (!response.raw) throw Error('Some Error Occurred');
        return response;
    };

    const publishToOracle = async (payload: any, poolWeek: string, advancePaymentPoolWeek: string) => {
        logEvent(EventConstants.PUBLISH_TO_GROWER, { poolWeek });
        const { token, userEmail } = await getTokenAndId(oktaAuth);
        const response = await postCall(POST_SETTLEMENT_RECORD(poolWeek), token, {}, payload);
        if (!response.raw) throw Error('Some Error Occurred');
        return response;
    };

    const post2Oracle = async (payload: any, poolWeek: string) => {
        logEvent(EventConstants.POST_TO_ORACLE, { poolWeek });
        const { token, userEmail } = await getTokenAndId(oktaAuth);
        const response = await postCall(POST_SETTLEMENT_RECORD(poolWeek), token, {}, payload);
        if (!response.raw) throw Error('Some Error Occurred');
        return response;
    };

    // approve reject
    const sendSettlementMail = async (payload: {
        Action: SettlementOps;
        PoolWeek: string;
        UserName?: string;
        UserEmail?: string;
        WebUrl?: string;
        ApproverName?: string;
        Comments?: string;
        ApprovedBy?: string;
        ApprovedDateTime?: string;
        GroupId?: string;
        RejectedBy?: string;
        RejectionReason?: string;
        RejectedDateTime?: string;
        SubmittedBy?: string;
        SubmittedDateTime?: string;
        GlueRequest?: any;
    }) => {
        const { token, userEmail } = await getTokenAndId(oktaAuth);
        let approverGroup = Object.keys(APPROVER_GROUP_ID).find(
            (group) => window.config.OKTA_GGS_ADMIN_GROUPS.includes(group) && group.indexOf(userBusinessUnit()) > -1
        );
        const groupId = APPROVER_GROUP_ID[approverGroup];
        if (payload.Action === SettlementActions.REQUEST4APPROVAL) {
            payload = { ...payload, UserName: userEmail?.split('@')[0], UserEmail: userEmail, GroupId: groupId };
        }
        const response = await postCall(SEND_SETTLEMENT_MAIL(), token, {}, payload);
        if (!response.raw) throw Error(`Error occurred while action ${payload.Action}`);
        return response.raw;
    };

    const acceptSettlement = async (poolWeek: string, comment: string) => {
        const { token } = await getTokenAndId(oktaAuth);
        let loggedInUserName = DuAuthenticationUtilities.GetEmail(token);
        let loggedInUserEmail = DuAuthenticationUtilities.GetUserId(token);
        const { userEmail } = await getTokenAndId(oktaAuth);
        return await sendSettlementMail({
            Action: 'APPROVE',
            GlueRequest: getGluePayLoad(GLUE_JOB_DATE_MOVEMENT, poolWeek, '', userEmail),
            PoolWeek: poolWeek,
            UserName: loggedInUserEmail,
            UserEmail: loggedInUserName,
            WebUrl: '',
            Comments: comment,
            ApproverName: userEmail,
            ApprovedBy: userEmail,
            ApprovedDateTime: DuDateUtilities.ToPrettyDate(new Date())
        });
    };

    const rejectSettlement = async (poolWeek: string, reason: string) => {
        const { userEmail, token } = await getTokenAndId(oktaAuth);
        let loggedInUserEmail = DuAuthenticationUtilities.GetEmail(token);
        let loggedInUserName = DuAuthenticationUtilities.GetUserId(token);

        return await sendSettlementMail({
            Action: 'REJECT',
            PoolWeek: poolWeek,
            UserName: loggedInUserName,
            UserEmail: loggedInUserEmail,
            WebUrl: '',
            RejectedBy: userEmail,
            RejectedDateTime: DuDateUtilities.ToPrettyDate(new Date()),
            RejectionReason: reason
        });
    };

    const getApprovers = async () => {
        try {
            const { token } = await getTokenAndId(oktaAuth);
            let approverGroup = Object.keys(APPROVER_GROUP_ID).find(
                (group) => window.config.OKTA_GGS_ADMIN_GROUPS.includes(group) && group.indexOf(userBusinessUnit()) > -1
            );
            const groupId = APPROVER_GROUP_ID[approverGroup];
            let response = await Call(
                {
                    name: 'ApproverUserList',
                    url: APIEndPoints.GET_APPROVER_LIST(APPROVER_GROUP_ID[approverGroup]),
                    method: 'GET',
                    options: {}
                },
                token
            );
            setApproverList(
                (response.raw.data || [])
                    .filter((user) => user.status === 'ACTIVE')
                    .map((usr) => ({
                        label: ` ${usr.profile.firstName} ${usr.profile.lastName} (${usr.profile.email})`,
                        value: usr.profile.email
                    }))
            );
        } catch (err) {}
    };

    return {
        getEntries,
        getDetailHeaders,
        getAggregate,
        getDetailsBreakdown,
        getSettlementEngine,
        getSettlementEngineStatus,
        sendSettlementMail,
        getSettlementApprovals,
        acceptSettlement,
        rejectSettlement,
        getApprovers,
        approverList,
        getGluePayLoad,
        getReportDetails,
        GLUE_JOB_PUBLISH_PDF,
        publishToOracle,
        post2Oracle
    };
};
export default useSettlement;
